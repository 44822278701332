export function overrideTags(
  title: string,
  description: string,
  imageUrl?: string
) {
  const tags = [
    { title },
    { name: 'description', content: description },
    {
      name: 'twitter:description',
      content: description,
    },
    { name: 'twitter:title', content: title },
    {
      property: 'og:description',
      content: description,
    },
    { property: 'og:title', content: title },
  ];

  if (imageUrl) {
    tags.push({
      name: 'twitter:image',
      content: imageUrl,
    });
    tags.push({
      property: 'og:image:secure_url',
      content: imageUrl,
    });
    tags.push({
      property: 'og:image',
      content: imageUrl,
    });
  }

  return tags;
}

export function creatorMetaTags(
  handle: string,
  title: string,
  description: string,
  color: string,
  imageUrl?: string,
  shouldNoIndex?: boolean
) {
  const tags = [
    { title },
    { name: 'description', content: description },
    {
      name: 'theme-color',
      content: color,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:domain', content: 'The Leap' },
    { name: 'twitter:title', content: title },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:site_name', content: 'The Leap' },
    { property: 'og:title', content: title },
    {
      property: 'og:description',
      content: description,
    },
    { property: 'og:type', content: 'profile' },
    // { property: 'og:updated_time', content: '1692824574000' },
    { property: 'og:url', content: `https://theleap.co/creator/${handle}` },
    // {
    //   property: 'snapchat:sticker',
    //   content: `https://theleap.co/creator/sticker/${handle}.png`,
    // },
  ];

  if (imageUrl) {
    tags.push({
      name: 'twitter:image',
      content: imageUrl,
    });
    tags.push({
      property: 'og:image:secure_url',
      content: imageUrl,
    });
    tags.push({ property: 'og:image:type', content: 'image/jpg' });
    tags.push({ property: 'og:image:height', content: '600' });
    tags.push({ property: 'og:image:width', content: '600' });
    tags.push({
      property: 'og:image',
      content: imageUrl,
    });
  }

  // Add noindex if 'shouldNoIndex' is true
  if (shouldNoIndex) {
    tags.push({ name: 'robots', content: 'noindex' });
  }

  return tags;
}
